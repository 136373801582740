<template>
  <modal :width="'50%'" :show="show" :title="title">
    <template v-slot>
      <v-row justify="end" align="end">
        <v-col class="pa-2 ma-0">
          <v-text-field
            class="ma-0 rounded-lg"
            hide-details
            type="text"
            outlined
            dense
            clearable
            :prepend-inner-icon="'mdi-magnify'"
            single-line
            v-model="searchValue"
          >
            <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="height: 50vh" class="elevation-5 pa-2">
          <v-data-table
            dense
            hide-default-footer
            height="100%"
            disable-pagination
            class="overflow-y-auto elevation-5 main-table table-readOnly"
            :items-per-page="-1"
            :headers="header"
            :items="filteredData"
            item-key="EquipmentID"
            v-model="
              objAuctionForm[modalContainerData.group][modalContainerData.index][
                'container_no'
              ]
            "
            show-select
          >
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <slot name="actions">
        <v-row class="ma-0 pa-0" justify="space-around">
          <v-col class="pa-0" cols="12">
            <v-btn
              class="elevation-5"
              color="primary"
              style="border: none"
              text
              light
              @click="$emit('closeModal')"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </slot>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { search } from "@/utils/panelAction.js";

import { mapActions } from "vuex";
const HEADER = [
  {
    text: "Container No.",
    value: "EquipmentID",
  },
  {
    text: "MRComments",
    value: "MRComments",
  },
];
export default {
  components: {
    Modal,
  },

  props: ["show", "title", "containerList", "modalContainerData", "objAuctionForm"],
  created() {
    this.filteredData = this.containerList;
  },
  data() {
    return {
      searchValue: "",
      filteredData: [],
    };
  },
  computed: {
    header() {
      return generateHeaderData(HEADER);
    },
  },
  watch: {
    searchValue() {
      this.filteredData = search({
        searchInput: this.searchValue,
        header: HEADER,
        arr: this.containerList,
      });
    },
  },

  methods: {
    ...mapActions({}),
  },
};
</script>
