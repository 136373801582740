<template>
  <v-data-table
    class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0 overflow-y-auto"
    dense
    fixed-header
    hide-default-footer
    disable-pagination
    height="100%"
    width="100%"
    :headers="headerData"
    :items="getInventoryQtySummsAvailables"
    @click:row="selectItem"
    item-key="group"
    :items-per-page="-1"
    :loading="loading"
    mobile-breakpoint="0"
  >
    <template v-slot:[`item.OverBooked`]="{ item }">
      <v-icon v-if="item.OverBooked == 'Y'" color="green">mdi-checkbox-marked</v-icon
      ><v-icon v-else color="green">mdi-minus-box</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { HEADER_QTYSUMM } from "@/helpers/mocks/inventoryqtysumm.js";
import { generateHeaderData } from "@/utils/tables.js";
export default {
  props: ["loading"],
  computed: {
    ...mapGetters({
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
    }),
    headerData() {
      return generateHeaderData(HEADER_QTYSUMM);
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("formOrderTableSelectedItem", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
