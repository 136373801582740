<template>
  <v-data-table
    class="elevation-5 ma-0 text--md rounded-0 main-table overflow-x-auto overflow-y-auto"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="items"
    disable-pagination
  >
    <template v-slot:[`item.actions`]="{ item }" class="primary">
      <v-row justify="space-around">
        <v-col class="col-ajust">
          <v-btn
            color="transparent"
            class="lighten-2 rounded-0"
            @click="deleteDepot(item)"
            icon
          >
            <v-icon color="error" small>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template v-slot:[`item.customerOrganizationId`]="{ item, header }">
      <DynamicField
        v-if="item.editable"
        :type="header.type"
        :value="header.value"
        :obj="item"
        :dropdownValues="dropdownValues"
        :flag="!item.editable"
      />

      <template v-else>
        {{ item.customerOrganizationName }}
      </template>
    </template>
  </v-data-table>
</template>

<script>
import DynamicField from "@/components/General/DynamicField.vue";
import { mapActions } from "vuex";
export default {
  props: [
    "items",
    "headerTable",
    "dropdownValues",
    "checkboxValues",
    "keyObject",
  ],
  components: {
    DynamicField,
  },
  data() {
    return {
      editRows: [],
    };
  },
  methods: {
    ...mapActions({
      deleteDepotStore: "moduleQtySumm/deleteDepot",
    }),
    async deleteDepot(item) {
      if (!item.editable) await this.deleteDepotStore(item);

      this.$emit("cancelNew");
    },
  },
};
</script>

<style></style>
