<template>
  <v-row class="py-1" v-cloak>
    <v-data-table
      class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0 table-header-expand overflow-y-auto"
      dense
      fixed-header
      hide-default-footer
      height="100%"
      width="100%"
      item-class="text-center red"
      disable-pagination
      :items="objAuctionForm[item.group]"
      :headers="headerDataExpanded"
      style="max-height: 500px"
    >
      <template v-slot:[`item.name`]="{ index }">
        <v-text-field
          class="ma-0 rounded-0 non-outlined text-subtitle-1"
          outlined
          full-width
          hide-details
          dense
          background-color="#303030"
          v-model="objAuctionForm[item.group][index]['name']"
        ></v-text-field>
      </template>
      <template v-slot:[`item.min_price`]="{ index }">
        <v-tooltip
          bottom
          :disabled="
            !!(
              errors &&
              errors[`${item.group}_price_${index}`] &&
              !errors[`${item.group}_price_${index}`].length
            )
          "
          color="warning"
        >
          <template v-slot:activator="{ on }">
            <ValidationProvider
              rules="required|numeric"
              :name="`${item.group}_price_${index}`"
              style="width: 100%"
              v-slot="{ errors }"
              immediate
            >
              <v-text-field
                class="ma-0 rounded-0 non-outlined text-subtitle-1"
                :class="errors && errors[0] ? 'outlined-error' : ''"
                outlined
                full-width
                hide-details
                dense
                background-color="#303030"
                v-model="objAuctionForm[item.group][index]['min_price']"
                v-on="on"
              ></v-text-field>
            </ValidationProvider>
          </template>

          <span class="font-weight-bold dark_blue--text">
            {{
              errors &&
              !!errors[`${item.group}_price_${index}`] &&
              !!errors[`${item.group}_price_${index}`].length
                ? errors[`${item.group}_price_${index}`][0]
                    .replace(`_${index}`, "")
                    .replace(`${item.group}_`, "")
                : ""
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.quantity`]="{ index }">
        <v-tooltip
          bottom
          :disabled="
            !!(
              errors &&
              errors[`${item.group}_quantity_${index}`] &&
              !errors[`${item.group}_quantity_${index}`].length
            )
          "
          color="warning"
        >
          <template v-slot:activator="{ on }">
            <ValidationProvider
              immediate
              :rules="`required|max_num:${minValue(
                inventoryParse[item.group].QtyOnHandAvailable,
                objAuctionForm[item.group],
                index
              )}||${minValueValidationQty(item.group, index)}`"
              :name="`${item.group}_quantity_${index}`"
              style="width: 100%"
              v-slot="{ errors }"
            >
              <v-text-field
                class="ma-0 rounded-0 non-outlined text-subtitle-1"
                :class="errors && errors[0] ? 'outlined-error' : ''"
                outlined
                full-width
                hide-details="auto"
                dense
                background-color="#303030"
                v-model="objAuctionForm[item.group][index]['quantity']"
                v-on="on"
                :error="errors[`${item.group}_quantity_${index}`]"
              ></v-text-field>
            </ValidationProvider>
          </template>

          <span class="font-weight-bold dark_blue--text">
            {{
              errors &&
              !!errors[`${item.group}_quantity_${index}`] &&
              !!errors[`${item.group}_quantity_${index}`].length
                ? errors[`${item.group}_quantity_${index}`][0]
                    .replace(`_${index}`, "")
                    .replace(`${item.group}_`, "")
                : ""
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.total`]="{ index }">
        <v-text-field
          class="ma-0 rounded-0 non-outlined text-subtitle-1"
          outlined
          full-width
          hide-details
          dense
          background-color="#303030"
          disable
          :value="
            Number(objAuctionForm[item.group][index].quantity) *
              Number(objAuctionForm[item.group][index].min_price) || 0
          "
        ></v-text-field>
      </template>
      <template v-slot:[`item.comments`]="{ index }">
        <v-textarea
          outlined
          rows="1"
          class="ma-0 rounded-0 non-outlined text-subtitle-1"
          full-width
          hide-details
          dense
          background-color="#303030"
          v-model="objAuctionForm[item.group][index]['comments']"
        ></v-textarea>
      </template>
      <template v-slot:[`item.container_no`]="{ index }">
        <v-autocomplete
          v-model="objAuctionForm[item.group][index]['container_no']"
          :items="
            objAuctionForm[item.group][index]['container_no']
              ? [
                  ...objAuctionForm[item.group][index]['container_no'],
                  ...containerNo_copy[item.group],
                ]
              : containerNo_copy[item.group]
          "
          @input="updateContainers(item.group)"
          color="white"
          dense
          solo
          background-color="#303030"
          hide-details
          class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24 text-subtitle-1"
          multiple
          return-object
          item-text="EquipmentID"
          item-value="EquipmentID"
        >
          <template #append-outer>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  :disabled="containersNo[item.group] && !containersNo[item.group].length"
                  @click="openContainersModal(item.group, index)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span class="text-subtitle-1 font-weight-bold"> See MR Comments </span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:[`item.actions`]="{ index }">
        <v-row class="flex-nowrap">
          <v-col
            class="pa-0 ma-0"
            v-for="{ icon, eventName } in tableActions"
            :key="icon"
          >
            <v-btn
              color="#20212E"
              class="lighten-2 rounded-0"
              block
              @click="eventName(item, index)"
              icon
            >
              <v-icon small dark>{{ icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: [
    "objAuctionForm",
    "item",
    "errors",
    "containerNo_copy",
    "containersNo",
    "inventoryParse",
  ],
  components: {
    ValidationProvider,
  },
  computed: {
    headerDataExpanded() {
      return generateHeaderData([
        { text: "Lot Name", value: "name", width: "20%", sortable: false },
        {
          text: "Min Price",
          value: "min_price",
          width: "10%",
          sortable: false,
        },
        { text: "Qty", value: "quantity", width: "10%", sortable: false },
        {
          text: "Lot Comments",
          value: "comments",
          width: "20%",
          sortable: false,
        },
        {
          text: "container No.",
          value: "container_no",
          width: "50%",
          sortable: false,
        },
        { text: "", value: "actions", width: "10%", sortable: false },
      ]);
    },
  },
  data() {
    return {
      tableActions: [
        {
          icon: "mdi-plus-box",
          eventName: this.copyItem,
          name: "copy",
        },
        { icon: "mdi-delete", eventName: this.deleteItem, name: "delete" },
      ],
    };
  },
  methods: {
    minValue(available, arr, indexF) {
      let sum = 0;

      for (let index = 0; index < indexF; index++) {
        sum += Number(arr[index].quantity || 0);
      }

      return Number(available) - sum;
    },
    minValueValidationQty(group, index) {
      return `min_num:${this.objAuctionForm[group][index].container_no?.length || 1}`;
    },
    copyItem(item) {
      let items = JSON.parse(JSON.stringify(this.objAuctionForm[item.group]));

      items.push({});

      this.$set(this.objAuctionForm, item.group, items);
    },
    deleteItem(item, index) {
      this.objAuctionForm[item.group].splice(index, 1);
    },
    updateContainers(group) {
      let containersSelected = [];

      this.objAuctionForm[group].forEach((x) => {
        if (x.container_no) containersSelected.push(...x.container_no);
      });

      this.containerNo_copy[group] = this.containersNo[group].filter((x) => {
        return !!!containersSelected.find((i) => {
          return i.EquipmentID === x.EquipmentID;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
