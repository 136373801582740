<template>
  <v-data-table
    class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    width="100%"
    item-key="group"
    item-class="text-center"
    show-select
    disable-pagination
    :items="getInventoryQtySummsAvailables"
    :headers="headerData"
    @item-selected="updateSelected"
    v-model="selected"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding: 20px !important"></td>
    </template>
  </v-data-table>
</template>

<script>
export default {};
</script>

<style></style>
