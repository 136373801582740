var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"py-1"},[_c('v-data-table',{staticClass:"elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0 table-header-expand overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":"","fixed-header":"","hide-default-footer":"","height":"100%","width":"100%","item-class":"text-center red","disable-pagination":"","items":_vm.objAuctionForm[_vm.item.group],"headers":_vm.headerDataExpanded},scopedSlots:_vm._u([{key:`item.name`,fn:function({ index }){return [_c('v-text-field',{staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","background-color":"#303030"},model:{value:(_vm.objAuctionForm[_vm.item.group][index]['name']),callback:function ($$v) {_vm.$set(_vm.objAuctionForm[_vm.item.group][index], 'name', $$v)},expression:"objAuctionForm[item.group][index]['name']"}})]}},{key:`item.min_price`,fn:function({ index }){return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!!(
            _vm.errors &&
            _vm.errors[`${_vm.item.group}_price_${index}`] &&
            !_vm.errors[`${_vm.item.group}_price_${index}`].length
          ),"color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"rules":"required|numeric","name":`${_vm.item.group}_price_${index}`,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g({staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",class:errors && errors[0] ? 'outlined-error' : '',attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","background-color":"#303030"},model:{value:(_vm.objAuctionForm[_vm.item.group][index]['min_price']),callback:function ($$v) {_vm.$set(_vm.objAuctionForm[_vm.item.group][index], 'min_price', $$v)},expression:"objAuctionForm[item.group][index]['min_price']"}},on))]}}],null,true)})]}}],null,true)},[_c('span',{staticClass:"font-weight-bold dark_blue--text"},[_vm._v(" "+_vm._s(_vm.errors && !!_vm.errors[`${_vm.item.group}_price_${index}`] && !!_vm.errors[`${_vm.item.group}_price_${index}`].length ? _vm.errors[`${_vm.item.group}_price_${index}`][0] .replace(`_${index}`, "") .replace(`${_vm.item.group}_`, "") : "")+" ")])])]}},{key:`item.quantity`,fn:function({ index }){return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!!(
            _vm.errors &&
            _vm.errors[`${_vm.item.group}_quantity_${index}`] &&
            !_vm.errors[`${_vm.item.group}_quantity_${index}`].length
          ),"color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"immediate":"","rules":`required|max_num:${_vm.minValue(
              _vm.inventoryParse[_vm.item.group].QtyOnHandAvailable,
              _vm.objAuctionForm[_vm.item.group],
              index
            )}||${_vm.minValueValidationQty(_vm.item.group, index)}`,"name":`${_vm.item.group}_quantity_${index}`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g({staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",class:errors && errors[0] ? 'outlined-error' : '',attrs:{"outlined":"","full-width":"","hide-details":"auto","dense":"","background-color":"#303030","error":errors[`${_vm.item.group}_quantity_${index}`]},model:{value:(_vm.objAuctionForm[_vm.item.group][index]['quantity']),callback:function ($$v) {_vm.$set(_vm.objAuctionForm[_vm.item.group][index], 'quantity', $$v)},expression:"objAuctionForm[item.group][index]['quantity']"}},on))]}}],null,true)})]}}],null,true)},[_c('span',{staticClass:"font-weight-bold dark_blue--text"},[_vm._v(" "+_vm._s(_vm.errors && !!_vm.errors[`${_vm.item.group}_quantity_${index}`] && !!_vm.errors[`${_vm.item.group}_quantity_${index}`].length ? _vm.errors[`${_vm.item.group}_quantity_${index}`][0] .replace(`_${index}`, "") .replace(`${_vm.item.group}_`, "") : "")+" ")])])]}},{key:`item.total`,fn:function({ index }){return [_c('v-text-field',{staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","background-color":"#303030","disable":"","value":Number(_vm.objAuctionForm[_vm.item.group][index].quantity) *
            Number(_vm.objAuctionForm[_vm.item.group][index].min_price) || 0}})]}},{key:`item.comments`,fn:function({ index }){return [_c('v-textarea',{staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",attrs:{"outlined":"","rows":"1","full-width":"","hide-details":"","dense":"","background-color":"#303030"},model:{value:(_vm.objAuctionForm[_vm.item.group][index]['comments']),callback:function ($$v) {_vm.$set(_vm.objAuctionForm[_vm.item.group][index], 'comments', $$v)},expression:"objAuctionForm[item.group][index]['comments']"}})]}},{key:`item.container_no`,fn:function({ index }){return [_c('v-autocomplete',{staticClass:"rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24 text-subtitle-1",attrs:{"items":_vm.objAuctionForm[_vm.item.group][index]['container_no']
            ? [
                ..._vm.objAuctionForm[_vm.item.group][index]['container_no'],
                ..._vm.containerNo_copy[_vm.item.group],
              ]
            : _vm.containerNo_copy[_vm.item.group],"color":"white","dense":"","solo":"","background-color":"#303030","hide-details":"","multiple":"","return-object":"","item-text":"EquipmentID","item-value":"EquipmentID"},on:{"input":function($event){return _vm.updateContainers(_vm.item.group)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.containersNo[_vm.item.group] && !_vm.containersNo[_vm.item.group].length},on:{"click":function($event){return _vm.openContainersModal(_vm.item.group, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" See MR Comments ")])])]},proxy:true}],null,true),model:{value:(_vm.objAuctionForm[_vm.item.group][index]['container_no']),callback:function ($$v) {_vm.$set(_vm.objAuctionForm[_vm.item.group][index], 'container_no', $$v)},expression:"objAuctionForm[item.group][index]['container_no']"}})]}},{key:`item.actions`,fn:function({ index }){return [_c('v-row',{staticClass:"flex-nowrap"},_vm._l((_vm.tableActions),function({ icon, eventName }){return _c('v-col',{key:icon,staticClass:"pa-0 ma-0"},[_c('v-btn',{staticClass:"lighten-2 rounded-0",attrs:{"color":"#20212E","block":"","icon":""},on:{"click":function($event){return eventName(_vm.item, index)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(_vm._s(icon))])],1)],1)}),1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }