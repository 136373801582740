<template>
  <v-row
    justify="center"
    class="py-2 px-2 fill-height overflow-y-auto"
    style="max-height: calc(100vh - 150px)"
  >
    <v-data-table
      class="elevation-5 main-table rounded-0 pa-0 ma-0"
      dense
      fixed-header
      hide-default-footer
      width="100%"
      height="100%"
      item-key="group"
      item-class="text-center"
      disable-pagination
      show-expand
      :items="tableSelected"
      :headers="headerDataMain"
      ref="datatable"
    >
      <template v-slot:[`item.Depot`]="{ item }">
        <v-row class="flex-nowrap">
          <v-col class="pa-0 ma-0">
            <v-chip outlined label>
              {{ item.Depot }}
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row class="flex-nowrap">
          <v-col class="pa-0 ma-0">
            <v-btn
              color="#20212E"
              class="lighten-2 rounded-0"
              block
              icon
              @click="removeBatch(item)"
            >
              <v-icon small dark color="red"> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.onHandAvailable`]="{ item, index }">
        <ValidationProvider
          rules="required|numeric"
          :name="`${item.group}_available_${index}`"
          style="width: 100%"
          v-slot="{ errors }"
          immediate
        >
          <v-text-field
            class="ma-0 rounded-0 non-outlined text-subtitle-1"
            :class="errors && errors[0] ? 'outlined-error' : ''"
            outlined
            full-width
            hide-details
            dense
            :value="
              !!objAuctionForm[item.group].length
                ? minValue(
                    inventoryParse[item.group].QtyOnHandAvailable,
                    objAuctionForm[item.group],
                    objAuctionForm[item.group].length
                  )
                : item.QtyOnHandAvailable
            "
          ></v-text-field>
        </ValidationProvider>
      </template>
      <template v-slot:[`item.data-table-expand`]="{}"> </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 20px !important">
          <GenerateLotForm :group="item.group" @copyItems="copyItems" :errors="errors" />

          <ExpandedTable
            :objAuctionForm="objAuctionForm"
            :item="item"
            :errors="errors"
            :containerNo_copy="containerNo_copy"
            :containersNo="containersNo"
            :inventoryParse="inventoryParse"
          />
        </td>
      </template>
    </v-data-table>
    <ModalContainersNo
      title="Containers No."
      v-if="flagContainersModal"
      :show="flagContainersModal"
      @closeModal="flagContainersModal = false"
      :containerList="containerList"
      :modalContainerData="modalContainerData"
      :objAuctionForm="objAuctionForm"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import TableAuctionForm from "@/components/InventoryQtySumm/TableAuctionForm.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ModalContainersNo from "./ModalContainersNo.vue";
import GenerateLotForm from "@/components/InventoryQtySumm/GenerateLotForm.vue";
import ExpandedTable from "@/components/InventoryQtySumm/ExpandedTable.vue";
export default {
  components: {
    DatePicker,
    TableAuctionForm,
    ValidationProvider,
    ValidationObserver,
    ModalContainersNo,
    GenerateLotForm,
    ExpandedTable,
  },
  props: ["tableSelected", "objAuctionForm", "containersNo", "errors"],
  mounted() {
    let obj = {};
    Object.keys(this.objAuctionForm).map((key) => {
      obj[key] = true;
    });

    this.$refs.datatable.expansion = obj;
  },
  computed: {
    ...mapState({
      batchInfo: (state) => state.moduleAuctions.batchInfo,
    }),
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
      getGoodRefeersWithGroup: "moduleQtySumm/getGoodRefeersWithGroup",
      getBadRefeersWithGroup: "moduleQtySumm/getBadRefeersWithGroup",
    }),
    inventoryParse() {
      let obj = {};
      this.getInventoryQtySummsAvailables.map((x) => (obj[x.group] = x));
      this.getGoodRefeersWithGroup.map((x) => (obj[x.group] = x));
      this.getBadRefeersWithGroup.map((x) => (obj[x.group] = x));
      return obj;
    },
    headerDataMain() {
      return generateHeaderData([
        { text: "City", value: "CityName", sortable: false },
        { text: "Depot Code", value: "Depot", sortable: false },
        { text: "Size", value: "Size", sortable: false },
        { text: "Type", value: "Type", sortable: false },
        { text: "Condition", value: "Cndtn", sortable: false },

        {
          text: "Available left",
          value: "onHandAvailable",
          width: "15%",
          sortable: false,
        },
        { text: "", value: "actions", sortable: false, width: "10px" },
      ]);
    },
  },
  watch: {
    tableSelected: {
      handler() {
        let obj = {};
        this.tableSelected.map((x) => {
          obj[x.group] = true;
        });
        this.$refs.datatable.expansion = obj;
      },
    },
    containersNo: {
      immediate: true,
      handler() {
        this.containerNo_copy = JSON.parse(JSON.stringify(this.containersNo));
      },
    },
  },
  data() {
    return {
      expandedItems: {},
      fields: [
        { label: "Country", value: "CountryAlhpa2" },
        { label: "city", value: "CityName" },
        { label: "depot code", value: "Depot" },
        { label: "condition", value: "Cndtn" },
        { label: "size", value: "Size" },
        { label: "type", value: "Type" },
      ],

      containerNo_copy: null,
      flagContainersModal: false,
      containerList: [],
      modalContainerData: {},
    };
  },

  methods: {
    ...mapMutations({
      addBatchInfo: "moduleAuctions/addBatchInfo",
    }),

    minValue(available, arr, indexF) {
      let sum = 0;

      for (let index = 0; index < indexF; index++) {
        sum += Number(arr[index].quantity || 0);
      }

      return Number(available) - sum;
    },

    removeBatch(batch) {
      let batches = this.tableSelected.filter((x) => {
        return x.group === batch.group;
      });

      this.$emit("updateSelected", { item: batches[0], value: false });
    },
    copyItems(newLots) {
      const { group, amount, qty, price } = newLots;
      if (amount && qty && price) {
        let items = JSON.parse(JSON.stringify(this.objAuctionForm[group]));

        for (let i = 0; i < amount; i++) {
          items.push({ min_price: price, quantity: qty });
        }
        this.$set(this.objAuctionForm, group, items);
      }
    },

    openContainersModal(group, index) {
      this.containerList = this.containerNo_copy[group];
      this.flagContainersModal = true;
      this.modalContainerData = { group, index };
    },
  },
};
</script>

<style scoped lang="scss">
.w-50 {
  width: 50%;
}

.border-primary {
  border: 1px solid var(--v-primary-base);
}
</style>

<style lang="scss">
.table-header-expand {
  padding: 10px !important;

  th {
    background-color: #9dd4ff !important;
  }
}
</style>
