<template>
  <v-data-table
    :headers="headerData"
    :items="inventoryAllocationData"
    dense
    height="50vh"
    hide-default-footer
    disable-pagination
    class="elevation-5 ma-0 pa-4 buyer-table text--md rounded-0"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, key) in headerData"
          :key="key"
          class="text-center pa-0 td-item"
        >
          <v-btn
            color="#20212E"
            class="lighten-2 rounded-0 elevation-5"
            block
            v-if="header.value === 'Action'"
            @click="showModal(item, 'Update Inventory Allocation')"
          >
            <span>Edit<v-icon dark>mdi-account-edit</v-icon></span>
          </v-btn>
          <span v-else style="font-size: small !important" class="elevation-5"
            >{{ item[header.value] }}
          </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  header,
  inventoryAllocationData,
} from "@/helpers/mocks/inventoryallocation.js";
import {
  get_inventory_allocations,
  update_toggle_inventory_allocation,
} from "@/helpers/api/indexv2.js";
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["inventoryAllocations"],
  data() {},
  computed: {
    headerData() {
      let headerData = [];

      for (let { value, title } of this.header) {
        // console.log(value);
        headerData.push({
          text: title,
          align: "center",
          sortable: true,
          value,
          class: "table-header text-capitalize primary",
        });
      }
      return headerData;
    },
  },
  async created() {
    await this.getInventoryAllocations();
  },
  watch: {
    inventoryAllocations() {
      this.inventoryAllocationData = [];
      this.inventoryAllocations.map((inventoryAllocation) => {
        this.inventoryAllocationData = [
          ...this.inventoryAllocationData,
          Object.assign({}, inventoryAllocation),
        ];
      });
      console.log("inventoryallocations in table-vue");
    },
  },
  data() {
    return { header, inventoryAllocationData: [], switch1: true };
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions(["getInventoryAllocations"]),
    showModal(item, title, paperRow) {
      console.log(item);
      this.$emit("showModal", { item, title, paperRow });
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.inventoryAllocation-table {
  width: 100%;
  .switch-center {
    .v-input--selection-controls__input {
      margin: auto;
    }
  }
  tr {
    background-color: #262737 !important;
  }

  td {
    border: none !important;
    border-bottom: solid thin #303030 !important;
    border-top: solid thin #303030 !important;
  }

  button {
    border: solid thin #262737 !important;
    font-size: small !important;
    color: #3284f4 !important;
  }
  .item-field {
    border: none;
  }

  .table-header {
    color: #212121 !important;
    border-bottom: none;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: medium !important;
    padding: 0;
    font-weight: 900;
  }

  .text-center {
    // border-top: thin solid rgba(255, 255, 255, 0.12);
    border-bottom: thin solid rgba(255, 255, 255, 0.12);
  }

  thead {
    background: #2196f3;

    -webkit-box-shadow: 0px 3px 5px -5px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
    -moz-box-shadow: 0px 3px 5px -5px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 3px 15px -5px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.v-data-table .v-data-table__wrapper table {
  border-spacing: 0 0.5rem;
}
</style>
