<template>
  <v-container fluid class="pt-1 pb-0">
    <v-row>
      <v-data-table
        class="elevation-5 main-table rounded-0 px-4 ma-0"
        dense
        fixed-header
        :headers="headerData"
        :items="items"
        hide-default-footer
        disable-pagination
        height="80vh"
        width="100%"
        item-class="text-center"
        item-key="GBCust"
        multi-sort
        :loading="loadingTable"
        ref="datatable"
        show-expand
      >
        <template v-slot:[`item.data-table-expand`]="{}"> </template>
        <template v-slot:expanded-item="{ headers, item }">
          <!-- <span>{{ JSON.stringify(itemExpansion[item.GBCust]) }}</span> -->
          <!--  overflow-y-auto -->
          <td :colspan="headers.length" style="padding: 20px !important">
            <v-data-table
              class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0 table-header-expand"
              dense
              fixed-header
              hide-default-footer
              height="100%"
              width="100%"
              item-class="text-center red"
              disable-pagination
              :items="itemExpansion[item.GBCust]"
              :headers="headerDataExpanded"
              style="max-height: 500px"
            />
          </td>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { gatebuy } from "@/helpers/api/indexv2.js";
export default {
  created() {
    this.loadData();
  },
  computed: {
    headerData() {
      return generateHeaderData([
        { text: "GBCust", value: "GBCust" },
        { text: "TotalCount", value: "TotalCount" },
      ]);
    },

    headerDataExpanded() {
      return generateHeaderData([
        { text: "Country", value: "CountryAlhpa2" },
        { text: "CityName", value: "CityName" },
        { text: "Depot", value: "Depot" },
        { text: "Size", value: "Size" },
        { text: "Type", value: "Type" },
        { text: "Condition", value: "Cndtn" },
        { text: "Status Code", value: "StatusCode" },
        { text: "GB Qty", value: "GBQty" },
        { text: "GB Customer", value: "GBCust" },
        { text: "TOTAL", value: "TOTAL_COUNT" },
        { text: "Group", value: "group" },
      ]);
    },
  },
  data() {
    return {
      items: [],
      loadingTable: false,
      itemExpansion: {},
    };
  },
  methods: {
    async loadData() {
      let res;
      console.log("D");
      this.loadingTable = true;
      try {
        res = await gatebuy();
        const items = res.data.d;
        const arr = [];
        console.log(items.length);
        const obj = items.reduce((acc, item) => {
          let key = item.GBCust;

          acc[key] = acc[key]
            ? [...acc[key], ...item.SizeTypeDepot]
            : [...item.SizeTypeDepot];

          return acc;
        }, {});

        const objExpansion = {};
        Object.keys(obj).map((key) => {
          objExpansion[key] = true;
        });
        this.itemExpansion = obj;
        this.$refs.datatable.expansion = objExpansion;
        this.items = items;
      } catch (error) {}

      this.loadingTable = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
