<template>
  <v-row class="ma-0 pa-2" dense>
    <v-col cols="12" class="flex-column elevation-5 px-5 py-2 dark_blue">
      <v-row class="pa-1" style="width: 100%; gap: 10px">
        <v-col class="btn--fit-col py-2" cols="12" v-if="info.QtyOnHandAvailable > 0">
          <v-btn class="button elevation-5" small color="primary" @click="newOrder">
            New Order
          </v-btn>
        </v-col>
        <v-col class="btn--fit-col py-2" cols="12">
          <v-btn
            class="button elevation-5"
            small
            color="primary"
            @click="setAuctionFormFlag"
            v-if="info.QtyOnHandAvailable > 0"
          >
            Create auction
          </v-btn>
        </v-col>
        <v-col class="btn--fit-col ml-auto" style="">
          <v-btn
            class="pa-0 ma-0 transparent elevation-0"
            dense
            @click="$emit('closePanelInfo')"
          >
            <v-icon dark class="red--text text--accent-4 ma-0">
              mdi-close-octagon
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="elevation-5 darker_blue pa-1" style="width: 100%">
        <v-col cols="12">
          <span class="pa-2 font-weight-bold">{{ info.DepotName }} </span>
        </v-col>

        <v-col cols="12">
          <span class="pa-2 font-weight-bold">{{ info.Depot }} </span>
        </v-col>
      </v-row>
    </v-col>

    <v-row class="ma-0 header-subtitles" dense justify="center">
      <v-col
        v-for="field in fields"
        :key="field.title"
        class="pa-1"
        :cols="field.col || 4"
      >
        <v-card elevation="5" class="pa-0 dark-blue fill-height">
          <v-list-item-content class="ma-0 pa-2 d-flex fill-height">
            <v-list-item-title class="ma-0 pa-2 text-center">
              {{ info[field.value] }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="ma-0 px-2 text-right primary--text font-weight-bold"
              tag="span"
            >
              {{ field.title }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-card>
      </v-col>
    </v-row>
    <v-col cols="12" class="text-right header-subtitles py-2 px-1">
      <span class="elevation-1 px-5 py-2 primary--text dark-blue font-weight-bold">
        Overbooked: {{ this.info.OverBooked }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: ["info"],
  computed: {
    address() {
      return `${this.info.CountryAlhpa2} - ${this.info.CityName}`;
    },
    sizeTypeCndt() {
      return `${this.info.Size} - ${this.info.Type}  ${this.info.Cndtn}`;
    },
    fields() {
      return [
        {
          title: "Country",
          value: "CountryAlhpa2",
          col: "6",
        },
        {
          title: "City",
          value: "CityName",
          col: "6",
        },
        {
          title: "Size",
          value: "Size",
        },
        {
          title: "Type",
          value: "Type",
        },
        {
          title: "Condition",
          value: "Cndtn",
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
      setAuctionFormFlagStore: "moduleQtySumm/setAuctionFormFlag",
      addBatchInfo: "moduleAuctions/addBatchInfo",
    }),
    newOrder() {
      this.setNewOrderFlag(true);
    },
    setAuctionFormFlag() {
      this.addBatchInfo(this.info);
      this.$router.push("/auctions/create-auction");
    },
  },
};
</script>

<style></style>
