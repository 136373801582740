<template>
  <v-row
    class="justify-center items-center mb-2 dark_blue ma-auto pa-4"
    style="width: 100%; min-height: 300px"
    dense
  >
    <v-col
      cols="8"
      class="d-flex flex-column py-2 px-2 darker_blue rounded-lg align-self-center"
      style="gap: 5px"
    >
      <v-row v-for="{ label, value, rules } of fieldsForm" :key="value" class="text-h6">
        <v-col cols="4" class="px-5 font-weight-bold text-capitalize d-flex align-center">
          <span class="elevation-24" v-if="value !== 'unregisterd'">
            {{ label }}
          </span>
        </v-col>
        <v-col cols="8" class="px-5 d-flex align-center">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="rules"
            :name="label"
            immediate
            tag="div"
            style="width: 100%"
          >
            <v-autocomplete
              v-if="value === 'customers'"
              v-model="obj[value]"
              :items="filteredListCustomers"
              color="white"
              dense
              solo
              clearable
              @click:clear="selectListDistribution = null"
              background-color="#20212E"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24"
              item-text="organization_descr"
              item-value="organization_id"
              :search-input.sync="autocompleteSearch"
              multiple
              return-object
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  color="primary"
                  small
                  close
                  @click:close="removeCustomer(item.organization_id)"
                  >{{ item.organization_descr }}
                </v-chip>
              </template>

              <template v-slot:prepend-item>
                <v-row style="gap: 5px; height: 30px" class="px-2 fill-height">
                  <v-col class="fill-height" style="height: 100%">
                    <v-btn block color="primary" @click="loadLastUsers" height="40px">
                      Load Last Customers Used
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-select
                      class="pa-0 ma-0"
                      background-color="primary"
                      label=" Load List Customers"
                      hide-details
                      solo
                      flat
                      small
                      :items="distributionList"
                      v-model="selectListDistribution"
                      item-text="name"
                      item-value="id"
                      outlined
                      single-line
                      filled
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>

            <div
              v-else-if="value === 'end_day' || value === 'start_day'"
              style="width: 100%"
            >
              <DatePicker
                v-model="obj[value]"
                :data="obj"
                :keyData="value"
                solo="false"
                flat="true"
                title=" "
                hideDetails="true"
                toFormat="yyyy-LL-dd"
                textfield_classes="elevation-24"
                :rules="`required`"
              >
              </DatePicker>
            </div>

            <v-textarea
              v-else-if="value === 'comments'"
              name="input-5-4"
              background-color="darker_blue elevation-24"
              solo
              v-model="obj[value]"
            ></v-textarea>

            <v-checkbox
              v-else-if="value === 'bypass'"
              name="input-5-5"
              background-color="darker_blue"
              solo
              rows="1"
              height="10px"
              :input-value="obj[value] === 'yes'"
              @change="
                (checkValue) => {
                  obj[value] = checkValue ? 'yes' : '';
                }
              "
            ></v-checkbox>

            <v-btn
              block
              color="primary"
              v-else-if="value === 'unregisterd'"
              @click="$emit('changeFlagModalUnreCust', true)"
              style="width: 100%"
              >Add Unregistered Customers <v-icon class="pl-2">mdi-eye</v-icon></v-btn
            >
            <v-text-field
              v-else
              class="ma-0 rounded-lg darker_blue elevation-24"
              background-color="darker_blue"
              dense
              solo
              clearable
              single-line
              v-model="obj[value]"
              hide-details
              :type="
                value === 'time_start_day' || value === 'time_end_day' ? 'time' : 'text'
              "
            >
            </v-text-field>

            <!-- !['end_day', 'start_day', 'time_end_day', 'time_start_day'].includes(
                  value
                ) && -->
            <span
              v-if="errors && errors.length"
              class="red--text text-caption text-capitalize"
            >
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- <div v-else-if="value === 'time_end_day'" style="width: 100%">
            <v-menu
              v-model="obj[`menu_${value}`]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                    :rules="rules"
                    name=" "
                    v-slot="{ errors, failedRules }"
                  >
                <v-text-field
                  v-model="obj[value]"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  hide-details
                  type="text"
                  dense
                  solo
                  clearable
                  single-line
                  background-color="#20212E"
                  class="ma-0 rounded-0 text-field__calendar elevation-5"
                  hint="YYYY-MM-DD format"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                </ValidationProvider>
              </template>
              <v-time-picker
                v-if="obj[`menu_${value}`]"
                v-model="obj[value]"
                full-width
                scrollable
              ></v-time-picker>
            </v-menu>
            {{ errors }}
          </div> -->
        </v-col>
      </v-row>
      <span class="text-center primary--text">
        Time Zone: {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
      </span>
    </v-col>
    <ModalUnregisteredCustomer
      v-if="flagModalUnreCust"
      :show="flagModalUnreCust"
      title="Add Unregistered Customers"
      :emailList="obj.customers"
      @closeModal="$emit('changeFlagModalUnreCust', false)"
      @clearList="clearList"
      @addNewUnregisterEmail="addNewUnregisterEmail"
      @removeUnregisterEmail="removeUnregisterEmail"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import ModalUnregisteredCustomer from "@/components/InventoryQtySumm/ModalUnregisteredCustomer.vue";
import { validate } from "vee-validate";

export default {
  components: { DatePicker, ValidationProvider, ModalUnregisteredCustomer },
  props: ["obj", "flagModalUnreCust", "distributionList", "fieldsForm"],
  computed: {
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
    }),

    endDayRule() {
      return `min_date:${86400000}`;
    },
    autocompleteCustomers() {
      return this.obj.customers;
    },
  },
  data() {
    return {
      // fieldsForm: [
      //   { label: "name", value: "name", rules: "required" },
      //   { label: "customers", value: "customers", rules: "required" },
      //   { label: "Unregistered", value: "unregisterd", rules: "required" },
      //   { label: "start day", value: "start_day", rules: "required" },
      //   { label: "start time", value: "time_start_day", rules: "required" },
      //   { label: "End day", value: "end_day", rules: "required" },
      //   { label: "End time", value: "time_end_day", rules: "required" },
      //   { label: "Comments", value: "comments", rules: "required" },
      //   { label: "Bypass invites?", value: "bypass", rules: "required" },
      // ],

      emailList: [],
      selectListDistribution: null,
      autocompleteSearch: "",
    };
  },
  watch: {
    selectListDistribution: {
      handler() {
        if (this.selectListDistribution) {
          let list = this.distributionList.filter(
            (x) => x.id === this.selectListDistribution
          );
          this.obj.customers = list[0].customers;
        }
      },
    },
    autocompleteCustomers: {
      handler() {
        this.autocompleteSearch = "";
      },
    },
  },
  methods: {
    ...mapActions({
      getLastCustAuction: "moduleAuctions/getLastCustAuction",
    }),
    removeCustomer(id) {
      this.obj.customers = this.obj.customers.filter((x) => x.organization_id !== id);
    },
    clearList() {
      this.$set(
        this.obj,
        "customers",
        this.obj.customers.filter((x) => !!x.organization_id)
      );
    },
    async pasteEmailListField(text) {
      let res = text;

      res = res.replaceAll(" ", "");
      res = res.replaceAll(";", ",");
      res = res.replaceAll(",", " ").split(/\r\n|\r|\n| /, -1);

      res.forEach(async (x) => {
        if (x.length) {
          let { valid } = await validate(x, "email");
          if (valid) {
            this.$set(this.obj, "customers", [
              ...this.obj.customers,
              { primary_email: x },
            ]);
          }
        }
      });
    },
    addNewUnregisterEmail(newEmail) {
      this.pasteEmailListField(newEmail);
    },
    removeUnregisterEmail(index) {
      const customers = [...this.obj.customers];
      customers.splice(index, 1);
      this.$set(this.obj, "customers", customers);
    },
    async loadLastUsers() {
      let res = await this.getLastCustAuction();

      res.forEach((x) => {
        if (
          !this.obj.customers.some(
            (value) => value.organization_id === x.customerEntityId
          )
        ) {
          this.obj.customers.push({
            organization_descr: x.organizationDescription,
            organization_id: x.customerEntityId,
            organization_type: "Customer",
            primary_email: x.customerEmail,
          });
        }
      });
    },
  },
};
</script>

<style>
input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}
</style>
