<template>
  <v-container class="elevation-5 pa-0 ma-0 text panel-buyer " fluid>
    <v-row dense class="justify-end align-center">
      <v-col cols="7" class="mr-auto ml-4">
        <v-text-field
          class="text-center ma-1 search-text"
          hide-details
          type="text"
          v-model="searchInventoryAllocation"
          @input="changeSearchValue"
          outlined
          dense
        >
          <template v-slot:label>
            <v-icon style="vertical-align: middle">mdi-magnify</v-icon>Search
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="2" class="d-flex mr-5">
        <v-btn color="#20212E" class="lighten-2 elevation-5" @click="add" block>
          <span>Add <v-icon dark>mdi-plus</v-icon></span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return { searchInventoryAllocation: "" };
  },
  methods: {
    add() {
      this.$emit("addInventoryAllocation");
    },
    changeSearchValue() {
      this.$emit("changeSearchValue", this.searchInventoryAllocation);
    },
  },
};
</script>

<style scoped>
.panel-buyer {
  background-color: #1e1e1e;
}

.panel-buyer .search-text {
  background-color: #20212e;
}
.panel-buyer button {
  border: solid thin #262737 !important;

  color: #3284f4 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #3284f4 !important;
}
</style>
