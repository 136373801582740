<template>
  <v-form ref="InventoryAllocationForm" v-model="formValid">
    <v-row class="ma-0 pa-0" v-for="(DataRow, index) in paperRows" :key="index">
      <v-col class="ma-0 pa-2 pt-3 pb-0">
        <v-card
          class="ma-auto text-end rounded-0 elevation-5"
          outlined
          color="#20212E"
        >
          <div
            class="text-overline ma-0 pr-4"
            v-show="paperRows.length == totalOfPaperRowsDefault"
          >
            {{ DataRow.title }}
          </div>

          <v-row class="ma-0 pa-0 row-paper">
            <v-col
              class="ma-0 pa-0"
              v-for="(field, index) in DataRow.fields"
              :key="index"
            >
              <v-text-field
                class="text-center ma-0"
                type="text"
                v-model="itemData[field.value]"
                :rules="field.rules ? [rules[field.rules]] : []"
                outlined
                dense
                v-if="field.type === 'Text'"
              >
                <template v-slot:label>
                  {{ field.title }}
                </template>
              </v-text-field>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsCountriesTmp2"           
          item-text="name"
          item-value="iso2"
          dense
          outlined
          label="Country"
          v-if="field.type === 'Select' && field.value === 'Country'"
        >
        </v-select>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsStates"           
          item-text="statename"
          item-value="stateid"
          dense
          outlined
          label="State"
          v-if="field.type === 'Select' && field.value === 'State'"
        >
        </v-select>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsCities"           
          item-text="name"
          item-value="id"
          dense
          outlined
          label="City"
          v-if="field.type === 'Select' && field.value === 'City'"
        >
        </v-select>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsDepots"           
          item-text="name"
          item-value="id"
          dense
          outlined
          label="Depot"
          v-if="field.type === 'Select' && field.value === 'Depot'"
        >
        </v-select>        
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsSizes"           
          item-text="description"
          item-value="code"
          dense
          outlined
          label="Size"
          v-if="field.type === 'Select' && field.value === 'Size'"
        >
        </v-select>        
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsTypes"           
          item-text="type_desc"
          item-value="code"
          dense
          outlined
          label="Type"
          v-if="field.type === 'Select' && field.value === 'Type'"
        >
        </v-select>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsCndtns"           
          item-text="description"
          item-value="code"
          dense
          outlined
          label="Condition"
          v-if="field.type === 'Select' && field.value === 'Cndtn'"
        >
        </v-select>
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsOrgs"           
          item-text="organization_descr"
          item-value="organization_id"
          dense
          outlined
          label="Buyer"
          v-if="field.type === 'Select' && field.value === 'PartnerID'"
        >
        </v-select>              
        <v-select
          v-model="itemData[field.value]"    
          :items="itemsTimeType"           
          item-text="typeDesc"
          item-value="timeType"     
          dense
          outlined
          label="Expiry Type"
          v-if="field.type === 'Select' && field.value === 'ExpiryTimeType'"
        >
        </v-select>

            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: ["itemData", "show", "paperRows", "itemsCountries", "itemsCountriesTmp"],
  data() {
    return {
      formValid: true,
      inventoryAllocationItem: {},
      totalOfPaperRowsDefault: 2,
      rules: {
        required: (value) => !!value || "Required.",
        number: (value) => {
          const pattern = /^\d+$/;

          if (`${value}`.length)
            return pattern.test(`${value}`) || "Only numbers";
          else return "Required.";
        },
      },
        itemsTimeType: [
          { typeDesc: 'Days', timeType: 'Days' },
          { typeDesc: 'Weeks', timeType: 'Weeks' },
          { typeDesc: 'Months', timeType: 'Months' },
          { typeDesc: 'Years', timeType: 'Years' },
        ],      
        itemsCountriesTmp2: [
          { name: 'United States of America', iso2: 'US' },
          { name: 'Canada', iso2: 'CA' },
        ],
    };
  },
  watch: {
    formValid() {
      this.$emit("saveEnable", this.formValid);
    },
  },
  mounted() {
    this.$emit("saveEnable", this.formValid);
  },
  methods: {},
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
.buyer-form {
  background-color: #1e1e1e;
}

.row-paper {
  flex-direction: column;
}
</style>
