<template>
  <v-container fluid class="pt-1 pb-0">
    <v-row justify="center">
      <v-col cols="auto">
        <Tabs
          :tab="tab"
          :tabValues="[{ title: 'REEFERS GOOD' }, { title: 'REEFERS OTHERS' }]"
          @changeTab="changeTab"
          :classes="`elevation-0  font-weight-bold text-uppercase`"
          :btnclasses="'primary  lighten-2 rounded-0 font-weight-light white--text font-weight-bold '"
          :classActive="'primary darken-1 white--text'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        class="elevation-5 main-table rounded-0 px-4 ma-0"
        dense
        fixed-header
        :headers="headerData"
        :items="items"
        hide-default-footer
        disable-pagination
        height="80vh"
        width="100%"
        item-class="text-center"
        multi-sort
        :loading="loadingTable"
      />
    </v-row>
  </v-container>
</template>

<script>
import Tabs from "@/components/General/Tabs.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { get_good_reefers, get_bad_reefers } from "@/helpers/api/indexv2.js";
export default {
  components: {
    Tabs,
  },
  created() {
    this.loadData();
  },
  computed: {
    headerData() {
      return generateHeaderData([
        { text: "Country", value: "CountryAlhpa2" },
        { text: "CityName", value: "CityName" },
        { text: "Depot", value: "Depot" },
        { text: "Size", value: "Size" },
        { text: "Type", value: "Type" },
        { text: "RFMachineryMFG", value: "RFMachineryMFG" },
        { text: "RFMachineryModel", value: "RFMachineryModel" },
        { text: "MRComments", value: "MRComments", width: "35%" },
        { text: "QtyOnHandTotalSell", value: "QtyOnHandTotalSell" },
      ]);
    },
  },
  data() {
    return {
      tab: 0,
      items: [],
      loadingTable: false,
    };
  },
  methods: {
    changeTab(value) {
      console.log(value);
      this.tab = value;
      this.loadData();
    },
    async loadData() {
      let res;
      switch (this.tab) {
        case 0:
          this.loadingTable = true;
          try {
            res = await get_good_reefers();
          } catch (error) {}

          this.loadingTable = false;
          break;
        case 1:
          this.loadingTable = true;
          try {
            res = await get_bad_reefers();
          } catch (error) {}

          this.loadingTable = false;
          break;
        default:
          this.loadingTable = true;
          try {
            res = await get_good_reefers();
          } catch (error) {}

          this.loadingTable = false;
          break;
      }
      this.items = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
