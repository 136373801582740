<template>
  <v-row class="text-start elevation-12 py-2 darker_blue">
    <v-col cols="12">
      <v-row class="">
        <v-col cols="3" class="d-flex justify-center items-center align-center">
          <span class="text-subtitle-1">Number of lots:</span>
          <v-text-field
            class="ma-0 rounded-0 text-subtitle-1 px-3"
            outlined
            full-width
            hide-details
            dense
            type="number"
            v-model="amount"
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="d-flex justify-center items-center align-center">
          <span class="text-subtitle-1">Qty:</span>
          <v-tooltip
            bottom
            :disabled="
              !!(
                errors &&
                errors[`${group}_generate`] &&
                !errors[`${group}_generate`].length
              )
            "
            color="warning"
          >
            <template v-slot:activator="{ on }">
              <ValidationProvider
                rules="max_num:10"
                :name="`${group}_generate`"
                style="width: 100%"
                v-slot="{ errors }"
              >
                <v-text-field
                  class="ma-0 rounded-0 text-subtitle-1 px-3"
                  :class="errors && errors[0] ? 'outlined-error' : ''"
                  v-on="on"
                  outlined
                  full-width
                  hide-details
                  dense
                  type="number"
                  v-model="qty"
                ></v-text-field>
              </ValidationProvider>
            </template>

            <span class="font-weight-bold dark_blue--text">
              {{
                errors &&
                !!errors[`${group}_generate`] &&
                !!errors[`${group}_generate`].length
                  ? errors[`${group}_generate`][0].replace(`${group}_generate`, "")
                  : ""
              }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="3" class="d-flex justify-center items-center align-center">
          <span class="text-subtitle-1">Min Price:</span>
          <v-text-field
            class="ma-0 rounded-0 text-subtitle-1 px-3"
            outlined
            full-width
            hide-details
            dense
            type="number"
            v-model="price"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="py-2">
          <v-btn
            class="button elevation-5"
            style="color: white !important"
            color="primary"
            @click="
              () => {
                $emit('copyItems', { group, amount, price, qty });
                cleanData();
              }
            "
          >
            Generate Lots
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: ["group", "errors"],
  data() {
    return {
      amount: "",
      qty: "",
      price: "",
    };
  },
  methods: {
    cleanData() {
      this.amount = "";
      this.qty = "";
      this.price = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
