var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"text-start elevation-12 py-2 darker_blue"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{},[_c('v-col',{staticClass:"d-flex justify-center items-center align-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Number of lots:")]),_c('v-text-field',{staticClass:"ma-0 rounded-0 text-subtitle-1 px-3",attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","type":"number"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('v-col',{staticClass:"d-flex justify-center items-center align-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Qty:")]),_c('v-tooltip',{attrs:{"bottom":"","disabled":!!(
              _vm.errors &&
              _vm.errors[`${_vm.group}_generate`] &&
              !_vm.errors[`${_vm.group}_generate`].length
            ),"color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"rules":"max_num:10","name":`${_vm.group}_generate`},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g({staticClass:"ma-0 rounded-0 text-subtitle-1 px-3",class:errors && errors[0] ? 'outlined-error' : '',attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","type":"number"},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}},on))]}}],null,true)})]}}])},[_c('span',{staticClass:"font-weight-bold dark_blue--text"},[_vm._v(" "+_vm._s(_vm.errors && !!_vm.errors[`${_vm.group}_generate`] && !!_vm.errors[`${_vm.group}_generate`].length ? _vm.errors[`${_vm.group}_generate`][0].replace(`${_vm.group}_generate`, "") : "")+" ")])])],1),_c('v-col',{staticClass:"d-flex justify-center items-center align-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Min Price:")]),_c('v-text-field',{staticClass:"ma-0 rounded-0 text-subtitle-1 px-3",attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","type":"number"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"button elevation-5",staticStyle:{"color":"white !important"},attrs:{"color":"primary"},on:{"click":() => {
              _vm.$emit('copyItems', { group: _vm.group, amount: _vm.amount, price: _vm.price, qty: _vm.qty });
              _vm.cleanData();
            }}},[_vm._v(" Generate Lots ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }