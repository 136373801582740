<template>
  <v-container fluid class="pt-1 pb-0">
    <v-row justify="center">
      <v-col cols="auto">
        <!-- { title: 'REEFERS GOOD' },
            { title: 'REEFERS OTHERS' }, -->
        <Tabs
          :tab="tab"
          :tabValues="[
            { title: 'All' },
            { title: 'REEFERS GOOD' },
            { title: 'REEFERS OTHERS' },
          ]"
          @changeTab="changeTab"
          :classes="`elevation-0  font-weight-bold text-uppercase`"
          :btnclasses="'primary  lighten-2 rounded-0 font-weight-light white--text font-weight-bold '"
          :classActive="'primary darken-1 white--text'"
        />
      </v-col>
    </v-row>

    <v-row class="dark-blue pa-0 py-2 justify-space-between align-center">
      <v-col cols="12">
        <v-text-field
          class="ma-0"
          hide-details
          type="text"
          outlined
          dense
          clearable
          :prepend-inner-icon="'mdi-magnify'"
          single-line
          v-model="searchInput"
        >
          <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-chip
          color="primary"
          small
          close
          label
          v-for="item in selectedProp"
          :key="item.group"
          class="ma-1"
          @click:close="updateSelected({ item, value: false })"
          >{{ `${item.Depot} ${item.Size}${item.Type}` }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row style="max-height: 1000px; overflow-y: auto">
      <v-data-table
        class="elevation-5 main-table table-readOnly rounded-0 pa-0 ma-0"
        dense
        fixed-header
        hide-default-footer
        height="100%"
        width="100%"
        item-key="group"
        item-class="text-center"
        show-select
        disable-pagination
        :items="items"
        :headers="headerData"
        @item-selected="updateSelected"
        @toggle-select-all="updateSelectedAll"
        v-model="selected"
      >
        <template #header.data-table-select="{}">
          <span></span>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { mapGetters, mapMutations, mapState } from "vuex";
import { search } from "@/utils/panelAction.js";

import Tabs from "@/components/General/Tabs.vue";

export default {
  props: ["selectedProp"],
  components: {
    Tabs,
  },
  computed: {
    ...mapState({
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
      goodRefeers: (state) => state.moduleQtySumm.goodRefeers,
      badRefeers: (state) => state.moduleQtySumm.badRefeers,
    }),
    ...mapGetters({
      newOrderFlag: "moduleOrders/newOrderFlag",
      getInventoryQtySummsAvailables: "moduleQtySumm/getInventoryQtySummsAvailables",
      getGoodRefeersWithGroup: "moduleQtySumm/getGoodRefeersWithGroup",
      getBadRefeersWithGroup: "moduleQtySumm/getBadRefeersWithGroup",
    }),
    headerData() {
      let values = {
        0: [
          { text: "Country", value: "CountryAlhpa2" },
          { text: "City", value: "CityName" },
          { text: "Depot Code", value: "Depot" },
          { text: "Size", value: "Size" },
          { text: "Type", value: "Type" },
          { text: "Condition", value: "Cndtn" },
          { text: "On Hand Available", value: "QtyOnHandAvailable" },
        ],
        1: [
          { text: "Country", value: "CountryAlhpa2" },
          { text: "CityName", value: "CityName" },
          { text: "Depot", value: "Depot" },
          { text: "Size", value: "Size" },
          { text: "Type", value: "Type" },
          { text: "RFMachineryMFG", value: "RFMachineryMFG" },
          { text: "RFMachineryModel", value: "RFMachineryModel" },
          { text: "MRComments", value: "MRComments" },
          { text: "QtyOnHandTotalSell", value: "QtyOnHandTotalSell" },
        ],
        2: [
          { text: "Country", value: "CountryAlhpa2" },
          { text: "CityName", value: "CityName" },
          { text: "Depot", value: "Depot" },
          { text: "Size", value: "Size" },
          { text: "Type", value: "Type" },
          { text: "RFMachineryMFG", value: "RFMachineryMFG" },
          { text: "RFMachineryModel", value: "RFMachineryModel" },
          { text: "MRComments", value: "MRComments", width: "35%" },
          { text: "QtyOnHandTotalSell", value: "QtyOnHandTotalSell" },
        ],
      };

      return generateHeaderData(values[this.tab]);
    },
    items() {
      let values = {
        0: "getInventoryQtySummsAvailables",
        1: "getGoodRefeersWithGroup",
        2: "getBadRefeersWithGroup",
      };
      return search({
        searchInput: this.searchInput,
        header: this.headerData,
        arr: this[values[this.tab]],
      });
    },
  },
  data() {
    return {
      selected: [],
      deleteBatchFlag: null,
      tab: 0,
      searchInput: "",
    };
  },
  watch: {
    selectedProp: {
      immediate: true,
      handler() {
        this.selected = this.selectedProp;
      },
    },
  },

  methods: {
    ...mapMutations({
      setOrdersData: "moduleOrders/setOrdersData",
      addBatchInfo: "moduleAuctions/addBatchInfo",
    }),
    updateSelected({ item, value }) {
      this.$emit("updateSelected", { item, value });
    },
    changeTab(value) {
      this.tab = value;
    },
    updateSelectedAll({ items, value }) {
      this.$emit("updateSelectedAll", { items, value });
    },
  },
};
</script>

<style lang="scss" scoped></style>
