var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"py-2 px-2 fill-height overflow-y-auto",staticStyle:{"max-height":"calc(100vh - 150px)"},attrs:{"justify":"center"}},[_c('v-data-table',{ref:"datatable",staticClass:"elevation-5 main-table rounded-0 pa-0 ma-0",attrs:{"dense":"","fixed-header":"","hide-default-footer":"","width":"100%","height":"100%","item-key":"group","item-class":"text-center","disable-pagination":"","show-expand":"","items":_vm.tableSelected,"headers":_vm.headerDataMain},scopedSlots:_vm._u([{key:`item.Depot`,fn:function({ item }){return [_c('v-row',{staticClass:"flex-nowrap"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-chip',{attrs:{"outlined":"","label":""}},[_vm._v(" "+_vm._s(item.Depot)+" ")])],1)],1)]}},{key:`item.actions`,fn:function({ item }){return [_c('v-row',{staticClass:"flex-nowrap"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-btn',{staticClass:"lighten-2 rounded-0",attrs:{"color":"#20212E","block":"","icon":""},on:{"click":function($event){return _vm.removeBatch(item)}}},[_c('v-icon',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v(" mdi-delete ")])],1)],1)],1)]}},{key:`item.onHandAvailable`,fn:function({ item, index }){return [_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"rules":"required|numeric","name":`${item.group}_available_${index}`,"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"ma-0 rounded-0 non-outlined text-subtitle-1",class:errors && errors[0] ? 'outlined-error' : '',attrs:{"outlined":"","full-width":"","hide-details":"","dense":"","value":!!_vm.objAuctionForm[item.group].length
              ? _vm.minValue(
                  _vm.inventoryParse[item.group].QtyOnHandAvailable,
                  _vm.objAuctionForm[item.group],
                  _vm.objAuctionForm[item.group].length
                )
              : item.QtyOnHandAvailable}})]}}],null,true)})]}},{key:`item.data-table-expand`,fn:function({}){return undefined}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticStyle:{"padding":"20px !important"},attrs:{"colspan":headers.length}},[_c('GenerateLotForm',{attrs:{"group":item.group,"errors":_vm.errors},on:{"copyItems":_vm.copyItems}}),_c('ExpandedTable',{attrs:{"objAuctionForm":_vm.objAuctionForm,"item":item,"errors":_vm.errors,"containerNo_copy":_vm.containerNo_copy,"containersNo":_vm.containersNo,"inventoryParse":_vm.inventoryParse}})],1)]}}],null,true)}),(_vm.flagContainersModal)?_c('ModalContainersNo',{attrs:{"title":"Containers No.","show":_vm.flagContainersModal,"containerList":_vm.containerList,"modalContainerData":_vm.modalContainerData,"objAuctionForm":_vm.objAuctionForm},on:{"closeModal":function($event){_vm.flagContainersModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }