<template v-slot:title>
  <div class="fill-height">
    <Header :info="info" @closePanelInfo="$emit('closePanelInfo')" />

    <v-divider
      :style="{ borderColor: 'var(--v-primary-base)', borderStyle: 'dashed' }"
    ></v-divider>

    <v-row class="ma-0 pa-2" style="height: 60%">
      <!-- <v-col cols="12" class="ma-0 pa-0 dark-blue fill-height"> -->
      <v-container fluid class="pa-0 ma-1 fill-height">
        <v-row justify="end" align="center" class="py-5">
          <v-col cols="auto">
            <span class="text-subtitle-1 font-weight-black"
              >Add TBD Qty:
            </span></v-col
          >
          <v-col class="px-5" cols="6">
            <v-row align="center">
              <v-col cols="6">
                <v-text-field
                  class="text-center"
                  hide-details
                  dense
                  outlined
                  single-line
                  type="number"
                  hide-spin-buttons
                  v-model.number="qtyTBD"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="pl-1">
                <v-btn
                  color="primary"
                  class="rounded-1 border-outlined"
                  block
                  @click="saveTBDContainers"
                >
                  add containers
                </v-btn>
              </v-col>
            </v-row></v-col
          >
        </v-row>
        <SidePanelTables :info="info" />
      </v-container>
      <!-- </v-col> -->
    </v-row>
  </div>
</template>

<script>
import RightPanel from "@/layout/RightPanel.vue";
import SidePanelTables from "@/components/InventoryQtySumm/Panel/SidePanelTables.vue";
import Header from "@/components/InventoryQtySumm/Panel/Header.vue";
import { mapActions } from "vuex";

export default {
  components: {
    RightPanel,
    SidePanelTables,
    Header,
  },
  props: ["info"],
  data() {
    return {
      qtyTBD: 0,
    };
  },
  methods: {
    ...mapActions({
      saveTBDContainersStore: "moduleQtySumm/saveTBDContainers",
    }),
    saveTBDContainers() {
      let { Size, Cndtn, Type, Depot, CountryAlhpa2, StateAlpha2, CityName } =
        this.info;

      let data = {
        numContainers: this.qtyTBD,
        Size,
        Type,
        Depot,
        Cndtn,
        Country: CountryAlhpa2,
        State: StateAlpha2,
        City: CityName,
        Status: "IN-CY",
      };

      this.saveTBDContainersStore({ data });
      this.qtyTBD = 0;
    },
  },
};
</script>

<style scoped lang="scss"></style>
