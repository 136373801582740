<template>
  <v-card class="ma-0 pa-0 transparent fill-height" style="width: 100%">
    <v-row class="ma-0 pa-2" justify="end">
      <template v-if="tab.index !== 0">
        <v-row v-if="!addFlag" dense class="pa-0 ma-0">
          <v-col class="pa-0 fill-height mr-auto" cols="3">
            <v-btn
              small
              color="dark-blue"
              class="lighten-2 rounded-1 border-outlined"
              dense
              block
              @click="addNewRow"
            >
              <span> <v-icon dark small>mdi-plus</v-icon> ADD </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else dense class="pa-0 ma-0" style="gap: 5px">
          <v-col class="pa-0 fill-height ma-0" cols="3">
            <v-btn
              small
              color="dark-blue"
              class="lighten-2 rounded-1 border-outlined"
              dense
              block
              @click="saveNew"
            >
              <span> SAVE </span>
            </v-btn>
          </v-col>
          <v-col class="pa-0 fill-height ma-0" cols="3">
            <v-btn
              small
              color="dark-blue"
              class="lighten-5 rounded-1 border-outlined"
              dense
              block
              @click="cancelNew"
            >
              <span> CANCEL </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-col
        v-for="(value, index) in tabValues"
        :key="value.title"
        class="pa-0 fill-height"
        style="flex-grow: 0"
      >
        <v-btn
          small
          class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
          :class="
            index === tab.index ? 'primary dark_blue--text bold-text' : ''
          "
          dense
          block
          @click="tabClicked(value)"
          :disabled="value.disable !== undefined ? !value.disable : false"
        >
          {{ value.title }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container class="pa-0 ma-0 elevation-5" style="height: 90%">
      <template v-if="tab.index === 0">
        <CustomersTable
          :headerTable="tab.headerData"
          :items="tableData"
          class="px-0 overflow-y-auto"
        />
      </template>
      <component
        v-else
        :headerTable="tab.headerData"
        :items="tab.items"
        :is="tab.component"
        :title="tab.title"
        :dropdownValues="tab.dropdownValues"
        :checkboxValues="tab.checkboxValues"
        :keyObject="tab.keyObject"
        class="px-0 overflow-y-auto"
        @cancelNew="cancelNew"
      >
      </component>
    </v-container>
  </v-card>
</template>

<script>
import CustomersTable from "@/components/InventoryQtySumm/CustomersTable.vue";
import CustomersTableTest from "@/components/InventoryQtySumm/CustomersTableTest.vue";

import { generateHeaderData } from "@/utils/tables.js";
import _ from "lodash";
import {
  HEADER_CUSTOMERSQTY,
  HEADER_DEPOTQTY,
} from "@/helpers/mocks/inventoryqtysumm.js";
import { mapActions, mapState } from "vuex";

export default {
  components: { CustomersTable, CustomersTableTest },
  props: ["info"],
  async created() {
    // if (!this.listCustomers.length) await this.getsOrganizations();
    this.tab = this.tabValues[0];
  },
  computed: {
    ...mapState({
      customersDepot: (state) => state.moduleQtySumm.customersDepot,
      coNames: (state) => state.moduleQtySumm.coNames,
      listCustomers: (state) => state.moduleOrganizations.list_organizations,
    }),
    tableData() {
      if (this.info.hasOwnProperty("Depot")) {
        try {
          let name = `${this.info.Depot.toLowerCase().trim()}_${this.info.Size.toLowerCase().trim()}_${this.info.Type.toLowerCase().trim()}_${this.info.Cndtn.toLowerCase().trim()}`;

          // if (this.coNames[name]) {

          return this.coNames[name];
          // }
        } catch (error) {
          console.log(error);
        }
      }
    },
    curatedCustomerList() {
      const customersDepot_ids = this.customersDepot.map(
        (x) => x.customerOrganizationId,
      );
      return this.listCustomers
        .map((x) => {
          return {
            customerOrganizationName: x.organization_descr,
            customerOrganizationId: x.organization_id,
          };
        })
        .filter((x) => !customersDepot_ids.includes(x.customerOrganizationId));
    },
    tabValues() {
      return [
        {
          index: 0,
          component: CustomersTable,
          title: "Booked Qty",
          headerData: generateHeaderData(HEADER_CUSTOMERSQTY),
          items: this.tableData ? this.tableData : [],
        },
        {
          index: 1,
          component: CustomersTable,
          title: "Depot Customers",
          headerData: generateHeaderData(HEADER_DEPOTQTY),
          dropdownValues: {
            values: this.curatedCustomerList ? this.curatedCustomerList : [],
            text: "customerOrganizationName",
            value: "customerOrganizationId",
          },

          checkboxValues: {
            falseValue: "N",
            trueValue: "Y",
          },
          items: this.customersDepot ? [...this.customersDepot] : [],
        },
        {
          index: 2,
          component: CustomersTableTest,
          title: "-",
          headerData: generateHeaderData(HEADER_DEPOTQTY),
          dropdownValues: {
            values: this.curatedCustomerList ? this.curatedCustomerList : [],
            text: "customerOrganizationName",
            value: "customerOrganizationId",
          },

          checkboxValues: {
            falseValue: "N",
            trueValue: "Y",
          },
          items: this.customersDepot ? [...this.customersDepot] : [],
        },
      ];
    },
  },

  data() {
    return {
      items: [],
      newItems: {},
      headerData: [],
      addFlag: false,
      tab: {},
    };
  },

  watch: {
    customersDepot() {
      if (this.customersDepot)
        this.tab = _.isEmpty(this.tab)
          ? this.tabValues[0]
          : this.tabValues[this.tab.index];
    },
  },
  methods: {
    ...mapActions({
      saveCustomersDepot: "moduleQtySumm/saveCustomersDepot",
      getsOrganizations: "moduleOrganizations/getsOrganizations",
    }),
    tabClicked(value, index) {
      this.tabIndex = index;
      this.tab = value;
    },

    addNewRow() {
      this.addFlag = true;

      let newValue = {
        ...this.curatedCustomerList[0],

        effectiveFrom: "",
        editable: true,
      };

      this.tabValues[1].items.push(newValue);
      this.newItems = newValue;
    },

    async saveNew() {
      await this.saveCustomersDepot({ ...this.newItems, info: this.info });
      this.addFlag = false;
    },
    cancelNew() {
      this.addFlag = false;
      this.tabValues[1].items.splice(this.tabValues[1].items.length - 1, 1);
    },
  },
};
</script>

<style lang="scss">
.selected-table {
  cursor: pointer;
}
</style>
