<template>
  <v-data-table
    class="elevation-5 ma-0 text--md rounded-0 main-table overflow-x-auto overflow-y-auto"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="[]"
    :items="items"
    disable-pagination
  >
    <template class="primary"> </template>
  </v-data-table>
</template>

<script>
import axios from "axios";

export default {
  props: ["items", "dropdownValues", "checkboxValues", "keyObject"],
  data() {
    return {
      editRows: [],
    };
  },
};
</script>

<style></style>
