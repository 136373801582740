<template>
  <v-row dense class="align-center elevation-5 pa-3 ma-0 dark-blue">
    <v-col xl="6" md="5" class="pa-0 ma-0">
      <v-text-field
        class="ma-0"
        hide-details
        type="text"
        outlined
        dense
        clearable
        :prepend-inner-icon="'mdi-magnify'"
        single-line
        v-model="searchInvSumm"
        @input="(value) => $emit('setSearchValue', searchInvSumm)"
      >
        <template v-slot:label
          ><span class="pa-0 ma-0"> Search </span>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="2" class="pa-0 ma-0 ml-2 d-flex">
      <v-btn class="primary" block @click="$emit('exportTemplate')"
        >Export Summary</v-btn
      >
    </v-col>
    <v-col cols="2" class="pa-0 ma-0 ml-2 d-flex">
      <v-btn class="primary" block @click="$emit('exportTemplateDepots')"
        >Export for Depots</v-btn
      >
    </v-col>

    <v-col
      cols="auto"
      style="float: right"
      class="pa-0 ma-0 mx-2 d-flex text-right"
    >
      <v-btn class="primary" @click="$emit('refreshData')"
        ><v-icon>mdi-refresh</v-icon></v-btn
      >
    </v-col>
    <v-col cols="auto" style="float: right" class="pa-0 ma-0 d-flex text-right">
      <v-menu
        offset-y
        :close-on-click="false"
        :close-on-content-click="false"
        min-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="primary" v-on="on"
            ><v-icon>mdi-filter-variant</v-icon></v-btn
          >
        </template>
        <v-card class="mx-auto darker_blue">
          <v-card-title tag="span" class="pa-2 font-weight-light">
            In this view, show records
          </v-card-title>

          <v-card-text class="pa-0 px-2">
            <v-row v-for="(filter, index) in filters" :key="index">
              <v-col class="d-flex justify-center align-center">
                <span
                  class="text-subtitle-2 px-2"
                  style="width: 100px"
                  v-if="index === 0 || index !== 1"
                >
                  {{ index === 0 ? "where" : filterOption }}
                </span>
                <v-select
                  v-else
                  style="width: 100px"
                  v-model="filterOption"
                  :items="['and', 'or']"
                  flat
                  outlined
                  single-line
                  dense
                  hide-details
                >
                </v-select>

                <v-autocomplete
                  v-model="filter.field"
                  :items="HEADER_QTYSUMM"
                  color="white"
                  dense
                  outlined
                  flat
                  background-color="#20212E"
                  hide-details
                  class="rounded-0 lighten-2 pa-0 ma-0 text-center"
                  item-text="text"
                  item-value="value"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="filter.condition"
                  :items="conditionals"
                  color="white"
                  dense
                  outlined
                  flat
                  background-color="#20212E"
                  hide-details
                  class="rounded-0 lighten-2 pa-0 ma-0 text-center"
                  item-text="text"
                  item-value="value"
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filter.value"
                  hide-details
                  dense
                  outlined
                  single-line
                  class="ma-0 rounded-5 text-field__calendar"
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="removeFilter(index)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn icon @click="addNewFilter"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <span>Add condition</span>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
import { HEADER_QTYSUMM } from "@/helpers/mocks/inventoryqtysumm.js";

export default {
  props: ["tableDataCopy"],
  data() {
    return {
      searchInvSumm: "",
      filters: [{ condition: "contains", field: "CountryAlhpa2", value: "" }],
      filterOption: "and",
      HEADER_QTYSUMM,
      conditionals: [
        { text: "contains...", value: "contains" },
        { text: "does not contain...", value: "does_not_contain" },
        { text: "is...", value: "is" },
        { text: "is not...", value: "is_not" },
      ],
      dataFiltered: [],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterData();
      },
    },
    filterOption() {
      this.filterData();
    },
  },
  methods: {
    ...mapMutations({
      setNewOrderFlag: "moduleOrders/setNewOrderFlag",
    }),
    newOrder() {
      this.setNewOrderFlag(true);
    },
    addNewFilter() {
      this.filters.push({
        condition: "contains",
        field: "CountryAlhpa2",
        value: "",
      });
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    filterData() {
      let arr = [];

      if (this.filterOption === "and") {
        this.dataFiltered = this.tableDataCopy;
        this.filters.map(({ condition, field, value }) => {
          if (value.length) {
            switch (condition) {
              case "contains":
                this.dataFiltered = this.dataFiltered.filter((x) => {
                  let dataValue = x[field].toLowerCase();
                  return dataValue.includes(value.toLowerCase());
                });

                break;
              case "does_not_contain":
                this.dataFiltered = this.dataFiltered.filter((x) => {
                  let dataValue = x[field].toLowerCase();

                  return !dataValue.includes(value.toLowerCase());
                });

                break;

              case "is":
                this.dataFiltered = this.dataFiltered.filter((x) => {
                  let dataValue = x[field].toLowerCase();

                  return dataValue === value.toLowerCase();
                });

                break;
              case "is_not":
                this.dataFiltered = this.dataFiltered.filter((x) => {
                  let dataValue = x[field].toLowerCase();

                  return dataValue !== value.toLowerCase();
                });

                break;
            }
          }
        });
      } else {
        let ids = [];
        this.filters.map(({ condition, field, value }) => {
          if (value.length) {
            switch (condition) {
              case "contains":
                this.tableDataCopy.forEach((x) => {
                  let dataValue = x[field].toLowerCase();
                  if (
                    dataValue.includes(value.toLowerCase()) &&
                    !ids.includes(x.group)
                  ) {
                    ids.push(x.group);
                    arr.push(x);
                  }
                });

                break;

              case "does_not_contain":
                this.tableDataCopy.forEach((x) => {
                  let dataValue = x[field].toLowerCase();
                  if (
                    !dataValue.includes(value.toLowerCase()) &&
                    !ids.includes(x.group)
                  ) {
                    ids.push(x.group);
                    arr.push(x);
                  }
                });
                break;
              case "is":
                this.tableDataCopy.forEach((x) => {
                  let dataValue = x[field].toLowerCase();
                  if (
                    dataValue === value.toLowerCase() &&
                    !ids.includes(x.group)
                  ) {
                    ids.push(x.group);
                    arr.push(x);
                  }
                });
                break;
              case "is_not":
                this.tableDataCopy.forEach((x) => {
                  let dataValue = x[field].toLowerCase();
                  if (
                    dataValue !== value.toLowerCase() &&
                    !ids.includes(x.group)
                  ) {
                    ids.push(x.group);
                    arr.push(x);
                  }
                });
                break;
            }
          }
        });

        this.dataFiltered = arr;
      }
      this.$emit("dataFiltered", this.dataFiltered);
    },
  },
};
</script>

<style></style>
