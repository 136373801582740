<template>
  <v-container
    fluid
    class="pa-0 table-panel-grid"
    :style="{ maxHeight: '1000px' }"
  >
    <v-data-table
      class="elevation-5 main-table rounded-0 px-4 ma-0"
      dense
      fixed-header
      :headers="headerData"
      :items="data"
      hide-default-footer
      disable-pagination
      height="80vh"
      width="100%"
      v-model="selected"
      item-key="group"
      item-class="text-center"
      @click:row="showInfoPanel"
      multi-sort
      :loading="loadingTable"
    >
      <template v-slot:[`item.OverBooked`]="{ item }">
        <div v-if="item.OverBooked == 0">
          <v-icon color="green">mdi-minus-box</v-icon>
        </div>

        <div v-if="item.OverBooked > 0">
          <v-chip
            class="ma-0"
            outlined
            color="yellow darken-4"
            text-color="white"
          >
            {{ item.OverBooked }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.QtyOnHandAvailable`]="{ item }">
        <div v-if="item.QtyOnHandAvailable > 0">
          <!-- <span style="color: lightgreen">{{ item.QtyOnHandAvailable }}</span> -->

          <v-chip class="ma-0" plain text-color="lightgreen">
            <span style="color: lightgreen">{{ item.QtyOnHandAvailable }}</span>
          </v-chip>
        </div>

        <div v-if="item.QtyOnHandAvailable == 0">
          <span style="color: rgba(255, 255, 255, 0.377)">{{
            item.QtyOnHandAvailable
          }}</span>
        </div>

        <div v-if="item.QtyOnHandAvailable < 0">
          <span style="color: orange">{{ item.QtyOnHandAvailable }}</span>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { HEADER_QTYSUMM } from "@/helpers/mocks/inventoryqtysumm.js";
import { generateHeaderData } from "@/utils/tables.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["data", "loadingTable"],
  computed: {
    ...mapGetters({
      newOrderFlag: "moduleOrders/newOrderFlag",
    }),

    headerData() {
      return generateHeaderData(HEADER_QTYSUMM);
    },
  },
  data() {
    return {
      inventoryQtySummData: [],
      selected: [],
    };
  },
  watch: {
    data() {
      this.inventoryQtySummData = this.data;
    },
  },
  beforeDestroy() {
    if (this.newOrderFlag) {
      this.setOrdersData(this.selected);
    }
  },

  methods: {
    ...mapMutations({
      setOrdersData: "moduleOrders/setOrdersData",
    }),
    showInfoPanel(item) {
      this.selected = [];
      this.selected.push(item);
      this.$emit("showInfoPanel", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
