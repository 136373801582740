<template>
  <modal :width="width || '50%'" :show="show" :title="title">
    <template v-slot>
      <v-row class="pa-2">
        <slot></slot>
        <v-col cols="12">
          <v-row justify="space-around" class="py-2" align="center">
            <v-col cols="10" class="px-1">
              <v-text-field
                v-model="newEmail"
                hide-details
                type="email"
                outlined
                clearable
                label="Emails, comma separated"
                class="ma-0 rounded-5"
                placeholder="bill1@address.com, email2@address.com"
                style="font-weight: 300 !important; font-size: 1.2rem"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pr-1">
              <v-btn block color="primary" @click="addNewUnregisterEmail">
                Add New Emails
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row justify="end" class="py-2" style="gap: 5px">
            <v-col cols="auto">
              <v-btn block color="primary" clas @click="$emit('clearList')">
                Clear List
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="height: 50vh" class="elevation-5 pa-2">
          <v-data-table
            dense
            hide-default-footer
            height="100%"
            disable-pagination
            class="overflow-y-auto elevation-5 main-table table-readOnly"
            :items-per-page="-1"
            :headers="header"
            :items="items"
            item-key="primary_email"
          >
            <template v-slot:[`item.primary_email`]="{ item }">
              <span class="text-body-1" style="letter-spacing: 2px">
                {{ item.primary_email }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ index }">
              <v-btn
                dark
                @click="deleteEmail(index)"
                color="#20212E"
                class="lighten-2 rounded-0 elevation-5"
                block
                icon
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <slot name="actions">
        <v-row class="ma-0 pa-0" justify="space-around">
          <v-col class="pa-0" cols="12">
            <v-btn
              class="elevation-5"
              color="primary"
              style="border: none"
              text
              light
              @click="$emit('closeModal')"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </slot>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { mapActions } from "vuex";
const HEADER = [
  {
    text: "Email",
    value: "primary_email",
  },
  {
    text: "",
    value: "actions",
    width: "50px",
  },
];
export default {
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
  },

  props: ["show", "title", "emailList", "width"],
  created() {},
  data() {
    return { newEmail: "" };
  },
  computed: {
    header() {
      return generateHeaderData(HEADER);
    },
    items() {
      return this.emailList.filter((x) => !x.organization_id);
    },
  },
  watch: {},
  methods: {
    ...mapActions({}),
    addNewUnregisterEmail() {
      this.$emit("addNewUnregisterEmail", this.newEmail);

      this.newEmail = "";
    },
    deleteEmail(index) {
      this.$emit("removeUnregisterEmail", index);
    },
  },
};
</script>
